import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../../core/services/LoaderService';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {OnlineCheckin} from '../../../models/guestview/OnlineCheckin';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment/moment';
import {GuestService} from '../../../core/services/guest.service';
import {ApiService} from '../../../core/http/api.service';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {Guide} from '../../../models/Guide';
import {GuestGuideDetailsComponent} from '../guest-guides/guest-guide-details/guest-guide-details.component';
import {MatDialog} from '@angular/material';
import {Photo} from '../../../models/Photo';
import {SmartLockAccess} from '../../../models/guestview/partners/SmartLockAccess';
import {GuestUpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';
import {GuestUpSellOnBoardingEndpoint} from '../../../core/services/V3_UpSellOnboarding/GuestUpSellOnBoardingEndpoint';

@Component({
  selector: 'app-m0-landing-page',
  templateUrl: './m0-landing-page.component.html',
  styleUrls: ['./m0-landing-page.component.scss']
})
export class M0LandingPageComponent extends AbstractWindow implements OnInit, AfterViewInit {

  _hasNext: boolean;
  index = 0;
  _hasPrevious: boolean;
  landingPageFragment = '';
  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  onlineCheck: OnlineCheckin;
  doorCode: string;
  isLockIcon: boolean = false;
  doorLink: string;
  doorLinkToClose: string;
  hasUpSells = false;
  hasImportantInfo = false;
  idForRouting: string;
  hasUnreadMessages: boolean = false;
  mainPropertyPhoto: string;
  currentUpSellOnBoarding: UpSellOnBoarding;

  constructor(private activatedRoute: ActivatedRoute,
              public router: Router,

              public utilService: UtilsService,
              private guestService : GuestService,
              private apiService: ApiService,
              public translateService: TranslateService,

              protected dialog: MatDialog,
              private loaderService: LoaderService,
              public guestUpSellOnBoardingEndpoint: GuestUpSellOnBoardingEndpoint) {
    super(utilService);
  }

  ngOnInit() {


    this.translateService.onLangChange.subscribe((event) => {
      console.log("language changed", event);
      let link = window.location.href;
      if (link.endsWith('/')) {
        link = link.substr(0, link.lastIndexOf('/'));
      }
      if (link.includes('?lang=')) {
        link = link.substr(0, link.lastIndexOf('?lang='));
      }
      window.location.href = link + '?lang=' + event.lang;
      window.location.reload();
    });

    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        this.guestService.sendBooking(this.booking);
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        this.currentUpSellOnBoarding = res.propertyAndBooking.upSellOnBoarding;
        this.idForRouting = this.property.id;
        if (this.booking) {
          this.idForRouting = this.booking.id;
          this.onlineCheck = res.propertyAndBooking.booking.onLineCheck;
        }
        this.hasUpSells = this.property.guides.filter(guide => guide.upSellSection != null && guide.upSellSection.showAsUpSell).length > 0;
        if (this.booking != null) {
          this.hasImportantInfo = this.property.guides.filter(guide => guide.showAsImportantInfo).length > 0;
        } else {
          this.hasImportantInfo = this.property.guides.filter(guide => guide.showAsImportantInfo).length > 0;
        }

        this.mainPropertyPhoto =   this.utilService.getMainPhoto(this.property);
        this._hasNext = this.hasNext();
        this._hasPrevious = this.hasPrevious();
        this.index = this.getPhotoIndex(this.property.photos, this.mainPropertyPhoto);

        if (this.booking) {
          if (this.booking.iglooHomeAccess != null) {
            this.doorCode = this.booking.iglooHomeAccess.code;
            this.doorLink = this.booking.iglooHomeAccess.guestUnlockUrl;
            this.doorLinkToClose = this.booking.iglooHomeAccess.guestLockUrl;
            this.isLockIcon = true;
          } else if (this.booking.theKeysAccesses != null) {
            this.doorCode = this.utilService.getTheKeysCode(this.booking);
            this.isLockIcon = false;
            this.doorLink = this.utilService.getTheKeysLink(this.booking);
          } else if (this.booking.doorLockCode != null) {
            this.doorCode = this.booking.doorLockCode;
            this.isLockIcon = false;
          }

          if (this.booking != null) {
            this.apiService.getMessages(this.booking.id).subscribe(msgs => {
              if (msgs != null) {
                this.hasUnreadMessages = msgs.filter(msg => msg.side === 'OUT' && msg.status !== 'READ').length > 0;
              }
            });
          }
        }

        //this.utilService.manageCorporateColors(this.corporate);
        console.log("this is the Res from the resolver", res);



      }
    });
  }

  ngAfterViewInit() {
    if (this.router.url.indexOf('/guides') > 0) {
      this.landingPageFragment = 'guides';
      if (this.router.url.substring(this.router.url.lastIndexOf("/") + 1) != 'guide') {
        const guideId = this.router.url.substring(this.router.url.lastIndexOf("/") + 1);
        this.property.guides.forEach(guide => {
          if (guide.guideId === guideId) {
            this.showMobileGuideDetails(guide);
          }
        });
      }
    } else if (this.router.url.indexOf('/recommendations') > 0) {
      this.landingPageFragment = 'recommendations';
    } else if (this.router.url.indexOf('/upsells') > 0) {
      this.landingPageFragment = 'upsells';
    }
    if (this.landingPageFragment !== '') {
      const element = document.querySelector("#" + this.landingPageFragment);
      if (element) {
        element.scrollIntoView(true);
      }
    }
  }

  isDuringTheStay(): boolean {
    if (this.booking) {
      return moment(this.booking.startDate).add(-1, 'days').isBefore(moment.now()) && moment(this.booking.endDate).add(1, 'days').isAfter(moment.now());
    } else {
      return false;
    }
  }
  isBeforeTheStay(): boolean {
    if (this.booking) {
      return moment(this.booking.startDate).add(-1, 'days').isAfter(moment.now());
    } else {
      return false;
    }
  }

  canShowDeposit() {
    if (this.booking) {
      if (this.property.nbDaysToShowDepositLink > 0) {
        return moment(this.booking.startDate).add(-this.property.nbDaysToShowDepositLink, 'days').isBefore(moment.now());
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getSmartLockAvailableDay() {
    if (this.booking != null) {
      return moment(this.booking.startDate).add(-1 * this.property.nbDaysToShowSmartLockCode, 'days').toISOString();
    } else {
      return "";
    }
  }

  isCautionDeposed(): boolean {
    let ret = true;
    if (this.isSwiklyConfigured() && this.booking.swiklyInfo.swiklyDepositAcceptedDate == null) {
      ret = false;
    }
    return ret;
  }

  isSwiklyConfigured() {
    return this.booking && this.booking.swiklyInfo && this.booking.swiklyInfo.swiklyAcceptUrl;
  }


  canShowSmartLock() {
    if (this.booking) {
      if (this.property.nbDaysToShowSmartLockCode > 0) {
        return moment(this.booking.startDate).add(-this.property.nbDaysToShowSmartLockCode, 'days').isBefore(moment.now());
      } else {
        return moment(this.booking.startDate).add(-1, 'days').isBefore(moment.now());
      }
    } else {
      return false;
    }
  }

  isSmartLockCodeAllowToShow(): boolean {
    // console.log(this.booking.startDate);
    // console.log(moment(this.booking.startDate).add(-5, 'days').format('YYY/MM/DD'));
    if (this.booking) {
      return moment(this.booking.startDate).add(-1 * this.getNbDaysForSmartLockCodeShow(), 'days').isBefore(moment.now());
    } else {
      return false;
    }
  }
  getNbDaysForSmartLockCodeShow(): number {
    return this.property.nbDaysToShowSmartLockCode != null ? this.property.nbDaysToShowSmartLockCode : 3;
  }

  showMap() {
    this.guestService.showMap(true, this.property, this.corporate);
  }

  getLanguages() {
    return UtilsService.languageArray;
  }

  getNonTranslatedLanguageName( languageCode: string) {
    switch (languageCode) {
      case 'fr':
        return 'Français';
      case 'en':
        return 'English';
      case 'de':
        return 'Deutsch';
      case 'es':
        return 'Español';
      case 'it':
        return 'Italiano';
      case 'nl':
        return 'Nederlands';
      case 'pt':
        return 'português';
      case 'da':
        return 'Dansk';
      case 'no':
        return 'Norsk';
      case 'ru':
        return 'русский';
      case 'pl':
        return 'Polskie';
      case 'sv':
        return 'Svenska';
      case 'tr':
        return 'Türk';
      case 'zh':
        return '中国人';
      case 'ja':
        return '日本人';
    }
  }

  changeLanguage(language: string) {

    //this.translateService.use(language);


    let link = window.location.href;
    if (link.endsWith('/')) {
      link = link.substr(0, link.lastIndexOf('/'));
    }
    if (link.includes('?lang=')) {
      link = link.substr(0, link.lastIndexOf('?lang='));
    }
    console.log('LINK', link);
    window.location.href = link + '?lang=' + language;
    window.location.reload();
  }

  showMobileGuideDetails(guide: Guide) {
    const taskCreationDialog = this.dialog.open(GuestGuideDetailsComponent, {
      height: this.isDesktopMode() ? '90%' : '100vh',
      width: this.isDesktopMode() ? '75%': '100vw',
      autoFocus: false,
      data: {
        property: this.property,
        guide: guide
      }
    });

  }

  next() {
    if (this._hasNext) {
      this.index++;
      if (this.property.photos[this.index].url) {
        this.mainPropertyPhoto = this.property.photos[this.index].url.replace('?aki_policy=medium', '');
      }
      this._hasNext = this.hasNext();
      this._hasPrevious = this.hasPrevious();
    }
  }

  previous() {
    if (this._hasPrevious) {
      this.index--;
      this.mainPropertyPhoto = this.property.photos[this.index].url.replace('?aki_policy=medium', '');
      this._hasNext = this.hasNext();
      this._hasPrevious = this.hasPrevious();
    }
  }

  hasNext(): boolean {
    let ret = false;
    if (this.property && this.property.photos
      && this.property.photos.length > 0 && this.mainPropertyPhoto) {
      const index = this.getPhotoIndex(this.property.photos, this.mainPropertyPhoto);
      ret = index !== this.property.photos.length - 1;
    }
    return ret;
  }

  hasPrevious(): boolean {
    let ret = false;
    if (this.property && this.property.photos
      && this.property.photos.length > 0 && this.mainPropertyPhoto) {
      const index = this.getPhotoIndex(this.property.photos, this.mainPropertyPhoto);
      ret = index > 0;
    }
    return ret;
  }

  private getPhotoIndex(photos: Photo[], photoUrl: string) {
    if (photos && photoUrl) {
      const fPhoto = photoUrl; //.replace('?aki_policy=large', '?aki_policy=medium');
      return photos.findIndex(p => p.url.startsWith(fPhoto));
    } else {
      return -1;
    }
  }
  getTime(date: string, defaultDate: string) {
    if (date) {
      return moment(date, 'hh:mm:ss').format('HH:mm').replace(':', 'h');
    } else if (defaultDate) {
      return (moment(defaultDate, 'hh:mm:ss').format('HH:mm')).replace(':', 'h');
    } else {
      return this.translateService.instant('guest-detail-booking.set-time');
    }
  }

  displayDuringStay(smartlockAccess: SmartLockAccess): boolean {
    //console.log("lockaccess", smartlockAccess.startDateTime);
    //console.log("moment now", moment());

    //console.log("moment", moment(smartlockAccess.startDateTime, "YYYY-MM-DD hh:mm"));
    //console.log("tsratTime", moment(smartlockAccess.startDateTime,  "YYYY-MM-DD hh:mm").isBefore(moment(), 'hour'));
    if (moment(smartlockAccess.startDateTime,  "YYYY-MM-DD hh:mm").isBefore(moment(), 'hour')
      && moment(smartlockAccess.endDateTime,  "YYYY-MM-DD hh:mm").isAfter(moment(), 'hour')) {
      return true;
    }
    return false;
  }


  checkIfUpSell() {
    return localStorage.getItem("guestUpSellOnBoarding") != null;
  }
  getNumbers() {
    let amount: number = 0;
    let currency = '';
    let guestUpSellOnboarding: GuestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
    if (guestUpSellOnboarding != null) {
      if (guestUpSellOnboarding.guides != null) {
        guestUpSellOnboarding.guides.forEach(guide => {
          if (guide.upSellSection != null && guide.upSellSection.upSellAmount != null) {
            if (guide.upSellSection.upSellAmount.amount != null) {
              currency = guide.upSellSection.upSellAmount.currency;
              if (guide.upSellSection.relatedQty != null) {
                amount += (guide.upSellSection.upSellAmount.amount * guide.upSellSection.relatedQty);
              } else {
                amount += guide.upSellSection.upSellAmount.amount;
              }
            }
          }
        });
      }
    }
    return amount + ' ' + currency.toUpperCase();
  }
  validateUpsell() {
    let guestUpSellOnboarding: GuestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
    this.guestUpSellOnBoardingEndpoint.validateBasket(guestUpSellOnboarding).subscribe( res => {
      this.currentUpSellOnBoarding = res;
      localStorage.removeItem("guestUpSellOnBoarding");

    });
  }
  resetBasket() {
    localStorage.removeItem("guestUpSellOnBoarding");
  }

  putPaymentMethod() {
    window.open(this.currentUpSellOnBoarding.intentConfirmationUrl, '_blank');
  }


}
