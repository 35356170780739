<div class="container-desktop gtp-back-color" >


  <div class="y3-flex-row y3-flex-justify-content-center y3-full-width y3-padding-top-42 y3-margin-bottom-24" >
    <div class="y3-flex-column y3-flex-align-center" *ngIf="booking">
      <div class="local-welcome ">{{'guest-top-page.welcome' | translate}} {{booking?.guests[0]?.firstName | titlecase}} {{booking?.guests[0]?.lastName | titlecase}}</div>
      <div *ngIf="booking != null && (booking.hostWords === '' || booking.hostWords == null)" class="y3-Large y3-steph-color-text">{{'m0-landing-page.guest-space' | translate}}</div>
      <div *ngIf="booking != null && (booking.hostWords !== '' || booking.hostWords != null)" class="y3-Large y3-steph-color-text">{{booking.hostWords}}</div>
    </div>
  </div>





  <div class="local-first-container">

    <section id="_________VIDEOS____________">
      <div class="y3-top-video-container" *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) !== DocType.YOUTUBE">
        <iframe style="object-fit: cover; width: 100%; height: calc(550px)" [src]="transform(property?.matterportUrl)"></iframe>
        <div class="y3-flex-column y3-flex-align-end" style="position: absolute; bottom: 10px; right: 10px">
          <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                      [text]="'guest-top-page.see-3D' | translate"
                      class="margin-bottom-8"
                      [type]="ButtonType.SHADOW"
                      [leftIcon]="Icons.PhotoCamera"
                      (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

          <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                      [text]="'guest-top-page.see-diapo' | translate"
                      class="margin-bottom-8"
                      [type]="ButtonType.SHADOW"
                      [leftIcon]="Icons.PhotoCamera"
                      (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

          <app-button *ngIf="property?.photos?.length > 0"
                      [text]="'guest-top-page.see-photos' | translate"
                      [type]="ButtonType.SHADOW"
                      [leftIcon]="Icons.PhotoCamera"
                      (debounceClick)="showImgDialog(photos[0])"></app-button>

          <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                      [type]="ButtonType.PRIMARY"
                      [routerLink]="['/guest/'+this.bookingId+'/review']"
                      class="margin-top-8"
          ></app-button>
        </div>
      </div>
      <div style="height: calc(550px); position: relative" *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) === DocType.YOUTUBE">
        <app-external-video-box [videoUrl]="property?.matterportUrl"></app-external-video-box>
        <div class="y3-flex-column y3-flex-align-end" style="position: absolute; bottom: 10px; right: 10px">
          <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                      [text]="'guest-top-page.see-3D' | translate"
                      class="margin-bottom-8"
                      [type]="ButtonType.SHADOW"
                      [leftIcon]="Icons.PhotoCamera"
                      (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

          <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                      [text]="'guest-top-page.see-diapo' | translate"
                      class="margin-bottom-8"
                      [type]="ButtonType.SHADOW"
                      [leftIcon]="Icons.PhotoCamera"
                      (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

          <app-button *ngIf="property?.photos?.length > 0"
                      [text]="'guest-top-page.see-photos' | translate"
                      [type]="ButtonType.SHADOW"
                      [leftIcon]="Icons.PhotoCamera"
                      (debounceClick)="showImgDialog(photos[0])"></app-button>

          <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                      [type]="ButtonType.PRIMARY"
                      [routerLink]="['/guest/'+this.bookingId+'/review']"
                      class="margin-top-8"
          ></app-button>
        </div>
      </div>
    </section>




    <section class="" id="slideshow" *ngIf="(!property.matterportUrl || property.noshowMatterportOnBooklet)">

        <div class="localcontainer">
          <div class="c_slider"></div>
          <div class="slider">
            <figure *ngIf="currentImage">
              <img [src]="currentImage.url.replace('?aki_policy=medium', '')" style="object-fit: cover" alt="" width="1250" height="520" />
              <!--<figcaption>The mirror of soul</figcaption>-->
            </figure>

            <div class="y3-flex-column y3-flex-align-end" style="position: absolute; bottom: 10px; right: 10px">
              <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                          [text]="'guest-top-page.see-3D' | translate"
                          class="margin-bottom-8"
                          [type]="ButtonType.SHADOW"
                          [leftIcon]="Icons.PhotoCamera"
                          (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

              <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                          [text]="'guest-top-page.see-diapo' | translate"
                          class="margin-bottom-8"
                          [type]="ButtonType.SHADOW"
                          [leftIcon]="Icons.PhotoCamera"
                          (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

              <app-button *ngIf="property?.photos?.length > 0"
                          [text]="'guest-top-page.see-photos' | translate"
                          [type]="ButtonType.SHADOW"
                          [leftIcon]="Icons.PhotoCamera"
                          (debounceClick)="showImgDialog(photos[0])"></app-button>

              <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                          [type]="ButtonType.PRIMARY"
                          [routerLink]="['/guest/'+this.bookingId+'/review']"
                          class="margin-top-8"
              ></app-button>
            </div>
          </div>



        </div>

        <span id="timeline"></span>


    </section>
  </div>

  <div class="y3-flex-row y3-flex-justify-content-center y3-full-width y3-margin-top-42 y3-margin-bottom-24">
    <div class="y3-flex-column y3-flex-align-center">
      <div class="local-welcome">{{property.bookletTitle? property.bookletTitle : property.title}}</div>
      <div class="y3-Large y3-roboto y3-steph-color-text y3-margin-bottom-24">{{property.fullAddress}}</div>
    </div>
  </div>


  <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-center y3-padding-bottom-8" *ngIf="booking">
    <div class="local-card-booking y3-margin-right-8">
      <div class="y3-Large local-title y3-margin-bottom-4" >{{'guest-top-page.start' | translate}}</div>
      <div class="y3-Medium local-title-normal y3-margin-bottom-8">{{booking?.startDate | moment: translateService.currentLang  | titlecase}} </div>
      <div class="y3-Large local-title">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</div>
    </div>

    <div class="local-card-booking-long y3-margin-right-8" *ngIf="booking">
      <div class="y3-Large local-title-normal y3-margin-bottom-4">{{booking?.nights}} {{'guest-top-page.nights' | translate}}</div>
      <div class="y3-Medium local-title-normal">{{booking?.adults}} {{'guest-top-page.adults' | translate}} | {{booking?.children}} {{'guest-top-page.children' | translate}} | {{booking?.babies}} {{'guest-top-page.infants' | translate}}</div>
    </div>

    <div class="local-card-booking" *ngIf="booking">
      <div class="y3-Large local-title y3-margin-bottom-4">{{'guest-top-page.end' | translate}}</div>
      <div class="y3-Medium local-title-normal y3-margin-bottom-8">{{booking?.endDate | moment: translateService.currentLang  | titlecase}} </div>
      <div class="y3-Large local-title">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</div>
    </div>
  </div>

  <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-center y3-padding-bottom-32 y3-margin-top-16">
    <div *ngIf="property?.showGuestInventory && booking?.id && isDuringTheDay()" class="y3-margin-right-8">
      <app-y3-explained-button  [title]="'guest-view-booking-details.inventory' | translate" [description]="'guest-view-booking-details.inventory-description' | translate" [routerLink]="['/guest/'+this.bookingId+'/inventory']"></app-y3-explained-button>
    </div>

    <section *ngIf="property.checkInOnline || property.lightOnLinePreCheck">
      <div *ngIf="!property.checkInOnline && !property?.host?.btobInfo && booking?.id" class="y3-margin-right-8">
        <app-y3-explained-button  [title]="'guest-view-booking-details.precheck' | translate" [description]="'guest-view-booking-details.precheck-description' | translate" [routerLink]="['/guest/'+this.bookingId+'/booking-setup']"></app-y3-explained-button>
      </div>
      <div *ngIf="property.checkInOnline && !property?.host?.btobInfo && booking?.id" class="y3-margin-right-8">
        <app-y3-explained-button  [title]="'guest-view-booking-details.precheck' | translate" [description]="'guest-view-booking-details.precheck-description' | translate" [routerLink]="['/guest/'+this.bookingId+'/online-check-in']"></app-y3-explained-button>
      </div>
    </section>

    <div *ngIf="!property?.host?.btobInfo && !property.noshowGuestReview && booking?.id" class="y3-margin-right-8">
      <app-y3-explained-button  [routerLink]="['/guest/'+this.bookingId+'/review']" [title]="'guest-view-booking-details.evaluate' | translate" [description]="'guest-view-booking-details.evaluate-description' | translate"></app-y3-explained-button>
    </div>
  </div>


  <div *ngIf="property?.matterportUrl === 'mememe'">
  <div class="y3-top-video-container" *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) !== DocType.YOUTUBE">
    <iframe style="object-fit: cover; width: 100%; height: calc(550px)" [src]="transform(property?.matterportUrl)"></iframe>
    <div class="y3-flex-column y3-flex-align-end" style="position: absolute; bottom: 10px; right: 10px">
      <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                  [text]="'guest-top-page.see-3D' | translate"
                  class="margin-bottom-8"
                  [type]="ButtonType.SHADOW"
                  [leftIcon]="Icons.PhotoCamera"
                  (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

      <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                  [text]="'guest-top-page.see-diapo' | translate"
                  class="margin-bottom-8"
                  [type]="ButtonType.SHADOW"
                  [leftIcon]="Icons.PhotoCamera"
                  (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

      <app-button *ngIf="property?.photos?.length > 0"
                  [text]="'guest-top-page.see-photos' | translate"
                  [type]="ButtonType.SHADOW"
                  [leftIcon]="Icons.PhotoCamera"
                  (debounceClick)="showImgDialog(photos[0])"></app-button>

      <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                  [type]="ButtonType.PRIMARY"
                  [routerLink]="['/guest/'+this.bookingId+'/review']"
                  class="margin-top-8"
      ></app-button>
    </div>
  </div>

  <div style="height: calc(550px); position: relative" *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) === DocType.YOUTUBE">
    <app-external-video-box [videoUrl]="property?.matterportUrl"></app-external-video-box>
    <div class="y3-flex-column y3-flex-align-end" style="position: absolute; bottom: 10px; right: 10px">
      <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                  [text]="'guest-top-page.see-3D' | translate"
                  class="margin-bottom-8"
                  [type]="ButtonType.SHADOW"
                  [leftIcon]="Icons.PhotoCamera"
                  (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

      <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                  [text]="'guest-top-page.see-diapo' | translate"
                  class="margin-bottom-8"
                  [type]="ButtonType.SHADOW"
                  [leftIcon]="Icons.PhotoCamera"
                  (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

      <app-button *ngIf="property?.photos?.length > 0"
                  [text]="'guest-top-page.see-photos' | translate"
                  [type]="ButtonType.SHADOW"
                  [leftIcon]="Icons.PhotoCamera"
                  (debounceClick)="showImgDialog(photos[0])"></app-button>

      <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                  [type]="ButtonType.PRIMARY"
                  [routerLink]="['/guest/'+this.bookingId+'/review']"
                  class="margin-top-8"
      ></app-button>
    </div>
  </div>


  <div class="y3-top-image-container" *ngIf="(!property.matterportUrl || property.noshowMatterportOnBooklet)">
    <div class="y3-images-container">
      <div class="y3-first-image">

        <img *ngIf="(!property?.matterportUrl || property.noshowMatterportOnBooklet) && property?.photos && currentImage"
             [@fade]="state" (@fade.done)="onDone($event)"
             class="y3-image fade" src="{{currentImage.url.replace('aki_policy=medium', '')}}">

        <!--<img class="y3-image" src="{{property.photos[0].url.replace('aki_policy=medium', 'aki_policy=xx_large').replace('aki_policy=large', 'aki_policy=xx_large')}}">-->
      </div>
      <div class="y3-second-and-third-images">
        <div class="y3-second-image" *ngIf="property.photos[1]">
          <img class="y3-image" src="{{property.photos[1].url.replace('aki_policy=medium', '')}}">
        </div>
        <div class="y3-third-image">
          <img *ngIf="property.photos[2]" class="y3-image" src="{{property.photos[2].url.replace('aki_policy=medium', '')}}">
          <div class="y3-flex-column y3-flex-align-end" style="position: absolute; bottom: 10px; right: 10px">
            <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                        [text]="'guest-top-page.see-3D' | translate"
                        class="margin-bottom-8"
                        [type]="ButtonType.SHADOW"
                        [leftIcon]="Icons.PhotoCamera"
                        (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

            <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                        [text]="'guest-top-page.see-diapo' | translate"
                        class="margin-bottom-8"
                        [type]="ButtonType.SHADOW"
                        [leftIcon]="Icons.PhotoCamera"
                        (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

            <app-button *ngIf="property?.photos?.length > 0"
                        [text]="'guest-top-page.see-photos' | translate"
                        [type]="ButtonType.SHADOW"
                        [leftIcon]="Icons.PhotoCamera"
                        (debounceClick)="showImgDialog(photos[0])"></app-button>

            <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                        [type]="ButtonType.PRIMARY"
                        [routerLink]="['/guest/'+this.bookingId+'/review']"
                        class="margin-top-8"
            ></app-button>
          </div>
        </div>
      </div>
      <div class="y3-second-and-third-images">
        <div class="y3-second-image" *ngIf="property.photos[3]">
          <img class="y3-image" src="{{property.photos[3]?.url.replace('aki_policy=medium', '')}}">
        </div>
        <div class="y3-third-image" *ngIf="property.photos[4]">
          <img class="y3-image" src="{{property.photos[4]?.url.replace('aki_policy=medium', '')}}">
        </div>
      </div>
    </div>

  </div>



  <div *ngIf="booking" class="y3-journey-summary ">

    <div class="y3-margin-1024 y3-welcome-display y3-McQueen ">{{'guest-top-page.welcome' | translate}} {{booking?.guests[0]?.firstName | titlecase}} {{booking?.guests[0]?.lastName | titlecase}}</div>
    <div *ngIf="booking != null && (booking.hostWords === '' || booking.hostWords == null)" class="y3-X-small y3-color-green-vogue">{{'m0-landing-page.guest-space' | translate}}</div>
    <div *ngIf="booking && booking.hostWords" class="y3-X-small y3-color-green-vogue">{{booking?.hostWords}}</div>

    <div class="y3-margin-1024 y3-margin-top-8 y3-Small y3-bold y3-roboto y3-color-green-vogue">{{property.bookletTitle? property.bookletTitle : property.title}}</div>
    <div class="y3-margin-1024 y3-X-small y3-roboto y3-color-scorpion">{{property.fullAddress}}</div>

    <div class="y3-margin-1024 y3-flex-row y3-roboto y3-margin-top-16" >
      <div class="y3-flex-column y3-roboto y3-check-card" >
        <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Medium y3-title-colors" >{{'guest-top-page.start' | translate}}</div>
        <div class="y3-color-scorpion y3-Medium">{{booking?.startDate | moment: translateService.currentLang  | titlecase}} </div>
        <div class="y3-color-scorpion y3-Medium">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</div>
      </div>
      <div class="y3-flex-column y3-roboto y3-check-card" >
        <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Medium y3-title-colors">{{'guest-top-page.end' | translate}}</div>
        <div class="y3-color-scorpion y3-Medium">{{booking?.endDate | moment: translateService.currentLang  | titlecase}} </div>
        <div class="y3-color-scorpion y3-Medium">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</div>
      </div>
      <div class="y3-flex-column y3-roboto y3-flex-align-center y3-flex-justify-content-center y3-check-card" style="width: 250px" >
        <div class="y3-margin-bottom-6">
          <app-tag *ngIf="property.needPreCheck && isPropertyReady()" [padding]="'8px'" [sizeText2]="'12px'" text2="{{'guest-view-booking-details.logement-ready' | translate}}" [type]="'green'"></app-tag>
          <app-tag *ngIf="property.needPreCheck && !isPropertyReady()" [padding]="'8px'" [sizeText2]="'12px'" text2="{{'guest-view-booking-details.logement-not-ready' | translate}}" [type]="'orange'"></app-tag>
        </div>
        <div class="y3-color-scorpion y3-X-small">{{booking?.nights}} {{'guest-top-page.nights' | translate}}</div>
        <div class="y3-color-scorpion y3-X-small">{{booking?.adults}} {{'guest-top-page.adults' | translate}} | {{booking?.children}} {{'guest-top-page.children' | translate}} | {{booking.babies}} {{'guest-top-page.infants' | translate}}</div>
      </div>
    </div>

    <!--
    <div class="y3-margin-1024 y3-flex-row y3-margin-top-32">
      <div class="y3-flex-column ">
        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-flex-justify-content-start">
          <div *ngIf="booking?.startDate !== booking?.endDate" class="y3-date-cards">
            <div class="date-card margin-right-32">
              <img src="assets/images/illustrations/men-fly.svg" alt="start">
              <div class="date-container">
                <p class="title">{{'guest-top-page.start' | translate}}</p>
                <p class="date">{{booking?.startDate | moment: currentLang  | titlecase}}</p>
                <p class="date">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</p>
              </div>
            </div>

            <div *ngIf="!property?.host?.btobInfo" class="y3-nights-container">
              <p class="Small">{{'guest-top-page.nights-desktop' | translate}}</p>
              <p class="number">{{booking?.nights}}</p>
            </div>

            <div class="date-card">
              <img src="assets/images/illustrations/men-luaggage.svg" alt="departure">
              <div class="date-container">
                <p class="title">{{'guest-top-page.end' | translate}}</p>
                <p class="date">{{booking?.endDate | moment: currentLang | titlecase}}</p>
                <p class="date">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <div class="y3-flex-row y3-flex-justify-content-end" style="position: absolute; right:8px">
      <div class="y3-flex-column y3-flex-justify-content-start" style="margin-right: 3px">
        <app-y3-explained-button *ngIf="property?.showGuestInventory && booking?.id && isDuringTheDay()" [title]="'guest-view-booking-details.inventory' | translate" [description]="'guest-view-booking-details.inventory-description' | translate" [routerLink]="['/guest/'+this.bookingId+'/inventory']"></app-y3-explained-button>
      </div>

      <div class="y3-flex-column y3-flex-justify-content-start">
        <app-y3-explained-button *ngIf="!property.noPreCheckInButton && !property.checkInOnline && !property?.host?.btobInfo && booking?.id" [title]="'guest-view-booking-details.precheck' | translate" [description]="'guest-view-booking-details.precheck-description' | translate" [routerLink]="['/guest/'+this.bookingId+'/booking-setup']"></app-y3-explained-button>
        <app-y3-explained-button *ngIf="property.checkInOnline && !property?.host?.btobInfo && booking?.id" [title]="'guest-view-booking-details.precheck' | translate" [description]="'guest-view-booking-details.precheck-description' | translate" [routerLink]="['/guest/'+this.bookingId+'/online-check-in']"></app-y3-explained-button>
        <app-y3-explained-button *ngIf="!property?.host?.btobInfo && !property.noshowGuestReview && booking?.id" [routerLink]="['/guest/'+this.bookingId+'/review']" [title]="'guest-view-booking-details.evaluate' | translate" [description]="'guest-view-booking-details.evaluate-description' | translate"></app-y3-explained-button>
      </div>

    </div>


  </div>
  </div>

</div>
