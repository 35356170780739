<div class="y3-container  g11-page-container" style="position: relative" *ngIf="property && booking">

  <div *ngIf="!property?.checkInOnline || onlineCheckIn.checkStatus === 'VALIDATED' || onlineCheckIn.checkStatus === 'SKIPPED'" class="g11-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>

  <div class="y3-main-card g11-card-height" >

    <div class="y3-flex-row y3-flex-justify-content-center y3-padding-bottom-16" *ngIf="corporate?.logoUrl">
      <img  style="max-width: 50%; max-height: 30vh" [src]="corporate.logoUrl" >
    </div>

    <div class="y3-flex-row y3-flex-justify-content-center">
      <div class="y3-High y3-roboto y3-margin-bottom-16 y3-bold y3-title-colors">{{'g11-online-checkin.title' | translate}}</div>
    </div>

    <div *ngIf="booking " class="g11-journey-summary ">

      <div class="y3-flex-responsive y3-flex-justify-space-between y3-flex-align-center">
        <div>
          <div class="y3-margin-1024 y3-Large y3-color-black y3-McQueen y3-margin-bottom-8">{{'guest-top-page.welcome' | translate}} {{booking?.guests[0]?.firstName | titlecase}} {{booking?.guests[0]?.lastName | titlecase}}</div>
          <div class="y3-margin-1024 y3-Small y3-bold y3-roboto y3-color-green-vogue">{{property.bookletTitle? property.bookletTitle : property.title}}</div>
          <div class="y3-margin-1024 y3-X-small y3-roboto y3-color-scorpion">{{property.fullAddress}}</div>

          <div class="y3-margin-1024 y3-flex-row y3-roboto y3-margin-top-16" >
            <div class="y3-flex-column y3-roboto g11-check-card" >
              <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Small">{{'guest-top-page.start' | translate}}</div>
              <div class="y3-color-scorpion y3-Small">{{booking?.startDate | moment: translateService.currentLang  | titlecase}} </div>
              <div class="y3-color-scorpion y3-Small">{{ utilsService.getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</div>
            </div>
            <div class="y3-flex-column y3-roboto g11-check-card" >
              <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Small">{{'guest-top-page.end' | translate}}</div>
              <div class="y3-color-scorpion y3-Small">{{booking?.endDate | moment: translateService.currentLang  | titlecase}} </div>
              <div class="y3-color-scorpion y3-Small">{{utilsService.getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</div>
            </div>
          </div>
        </div>


      <div class="y3-flex-row y3-margin-top-16 y3-flex-align-center"  *ngIf="property.checkInOnline">
        <app-tag *ngIf="onlineCheckIn.checkStatus === 'VALIDATED'" [type]="'green'" text="{{'g11-online-checkin.validated' | translate | uppercase}}" ></app-tag>
        <app-tag *ngIf="onlineCheckIn.checkStatus === 'WAITING' || onlineCheckIn.checkStatus === 'NOUVEAU'" [type]="'orange'" text="{{'g11-online-checkin.waiting-for-approval' | translate | uppercase}}" ></app-tag>
        <app-tag *ngIf="onlineCheckIn.checkStatus === 'REFUSED'" [type]="'red'" text="{{onlineCheckIn.checkStatus}}" ></app-tag>
      </div>
    </div>
    </div>


    <section class="y3-X-small y3-margin-top-8" id="STEPMODE_NON_EDIT" *ngIf="currentMode === 'STEP_NON_EDIT'">
      <div style="background-color: #FAFAFA; border: 1px solid gainsboro;" class="y3-padding-8">

        <div class="y3-text y3-margin-top-4 y3-bold">&nbsp;{{onlineCheckIn.firstName}} {{onlineCheckIn.lastName}}</div>
        <div class="y3-text y3-margin-top-4">&nbsp;{{onlineCheckIn.email}}</div>
        <div class="y3-text y3-margin-top-4">&nbsp;{{onlineCheckIn.phoneNumber}}</div>

        <!--<div *ngIf="onlineCheckIn.stayType" class="y3-text y3-margin-top-16">{{'g11-online-checkin.stay-type' | translate}}</div>
        <div *ngIf="onlineCheckIn.stayType" class="y3-label-text-small y3-margin-top-4">&nbsp;{{stayType}}</div>
        <div *ngIf="onlineCheckIn.stayInterests" class="y3-text y3-margin-top-8">{{'g11-online-checkin.stay-interest' | translate}}</div>
        <div *ngIf="onlineCheckIn.stayInterests" class="y3-label-text-small y3-margin-top-4">&nbsp;{{stayInterest}}</div>
        <div *ngIf="onlineCheckIn.stayTransport" class="y3-text y3-margin-top-8">{{'g11-online-checkin.stay-transport-type' | translate}}</div>
        <div *ngIf="onlineCheckIn.stayTransport" class="y3-label-text-small y3-margin-top-4">&nbsp;{{'g11-online-checkin.' + onlineCheckIn.stayTransport | translate}}</div>-->

        <div class="y3-flex-row">
          <div class="y3-flex-column">
            <div class="y3-text y3-margin-top-8">{{'g11-online-checkin.check-in-time' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">&nbsp;{{onlineCheckIn.onLineCheckInTime}}</div>
          </div>

          <div class="y3-flex-column y3-margin-left-8">
            <div class="y3-text y3-margin-top-8">{{'g11-online-checkin.check-out-time' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">&nbsp;{{onlineCheckIn.onLineCheckOutTime}}</div>
          </div>

          <div class="y3-flex-column y3-flex-align-end y3-flex-justify-content-end y3-margin-left-8">
            <div>{{onlineCheckIn.adults}} {{'guest-setup-page.adults' | translate}}</div>
            <div>{{onlineCheckIn.children}} {{'guest-setup-page.children' | translate}}</div>
            <div>{{onlineCheckIn.babies}} {{'guest-setup-page.infants' | translate}}</div>
          </div>



            <!--<div class="y3-flex-column y3-flex-align-end y3-flex-justify-content-end y3-margin-left-8" *ngIf="!property.noshowBedsDetail">
              <div>{{onlineCheckIn.doubleBeds}} {{'g11-online-checkin.double' | translate}}</div>
              <div>{{onlineCheckIn.singleBeds}} {{'g11-online-checkin.single' | translate}}</div>
              <div>{{onlineCheckIn.sofaBeds}} {{'g11-online-checkin.sofa' | translate}}</div>
            </div>-->

        </div>

        <div *ngIf="property?.checkinOnlineWithContract" >
          <div class="y3-Small y3-margin-top-16 y3-bold y3-title-colors">{{'g11-online-checkin.rental-contract' | translate}}</div>
          <div class="y3-margin-bottom-8 y3-margin-top-8 y3-X-small" *ngIf="onlineCheckIn.contractDocumentUrlSigned">
            <a href="{{onlineCheckIn.contractDocumentUrlSigned}}" target="_blank">{{'g11-online-checkin.contract-signed' | translate}}</a>
          </div>
        </div>

        <div *ngIf="property.checkInOnline && !property.checkinOnlineWithNoIdentityProof
      && ((onlineCheckIn.checkStatus === 'VALIDATED' && onlineCheckIn.identityProofUrls?.length > 0) || (onlineCheckIn.checkStatus !== 'SKIPPED' && onlineCheckIn.checkStatus !== 'VALIDATED')) ">
          <div class="y3-Small y3-bold y3-margin-top-16 y3-title-colors">{{'g11-online-checkin.personal-proofofidentity' | translate}}</div>

          <div class="y3-flex-column y3-margin-top-8 y3-X-small" >
            <div class="y3-X-small " *ngFor="let document of onlineCheckIn.identityProofUrls">
              <div class="y3-margin-bottom-8 ">
                <a href="{{document}}" target="_blank">{{getDocumentName(document)}}</a>
              </div>
            </div>
          </div>

        </div>


        <div class=" y3-margin-top-16" *ngIf="currentMode==='STEP_NON_EDIT'">
          <app-button [type]="'secondary'" [text]="'g11-online-checkin.edit' | translate" (debounceClick)="edit()"></app-button>
        </div>

      </div>
    </section>



    <section id="STEP_INFORMATION" style="position: relative" *ngIf="currentStepName === StepName.STEP_INFORMATION && currentMode === 'STEP_EDIT'">

      <div *ngIf="!property.checkInOnlineNoAskForInterest ||
      !property.checkInOnlineNoAskForTransport || !property.checkInOnlineNoAskForTypeOfStay || property.possiblePhysicalWelcome"
 class="y3-Medium y3-margin-top-16 y3-bold y3-title-colors y3-margin-bottom-8">{{'g11-online-checkin.your-desire' | translate}} </div>

      <div class="y3-flex-responsive y3-margin-bottom-8 " *ngIf="!property.checkInOnlineNoAskForTypeOfStay">
        <div class="y3-flex-column y3-X-small g11-input-margin">
          <div>{{'g11-online-checkin.stay-type' | translate}}</div>

          <div class="y3-margin-top-4">
          <ng-multiselect-dropdown class="" [placeholder]="'g11-online-checkin.choose-type-stay' | translate"
                                   [settings]="dropdownSettingsNoFilter"
                                   [data]="stayTypes"
                                   [(ngModel)]="stayType"
                                   (onSelect)="selectType($event)"
                                   (onDeSelect)="unSelectType($event)"
          >
          </ng-multiselect-dropdown>
          </div>

        </div>
      </div>

      <div class="y3-flex-responsive y3-margin-bottom-8 y3-margin-top-8" *ngIf="!property.checkInOnlineNoAskForInterest">
        <div class="y3-flex-column y3-X-small g11-input-margin">
          <div>{{'g11-online-checkin.stay-interest' | translate}}</div>

          <div class="y3-margin-top-4">
            <ng-multiselect-dropdown class="" [placeholder]="'g11-online-checkin.choose-interest' | translate"
                                     [settings]="dropdownSettingsMultiple"
                                     [data]="interestTypes"
                                     [(ngModel)]="stayInterest"
                                     (onSelect)="selectInterest($event)"
                                     (onDeSelect)="unSelectInterest($event)"
            >
            </ng-multiselect-dropdown>
          </div>

        </div>
      </div>


      <div class="y3-flex-responsive y3-margin-bottom-8 y3-margin-top-8" *ngIf="!property.checkInOnlineNoAskForTransport">
        <div class="y3-flex-column y3-X-small g11-input-margin">
          <div>{{'g11-online-checkin.stay-transport-type' | translate}}</div>

          <div class="y3-margin-top-4">
            <ng-multiselect-dropdown class="" [placeholder]="'g11-online-checkin.choose-transport' | translate"
                                     [settings]="dropdownSettingsNoFilter"
                                     [data]="transportTypes"

                                     [(ngModel)]="stayTransport"
                                     (onSelect)="selectTransport($event)"
                                     (onDeSelect)="unSelectTransport($event)"
            >
            </ng-multiselect-dropdown>
          </div>

        </div>
      </div>

      <div class="y3-Small" *ngIf="property.possiblePhysicalWelcome">
        <!--CheckBox for asking physical welcome-->
        <div class="y3-Small">{{'g11-online-checkin.physical-welcome' | translate}}</div>
        <div class="y3-margin-top-4">
          <ng-multiselect-dropdown class="" [placeholder]="'Choisissez'"
                                   [settings]="dropdownSettingsNoFilter"
                                   [data]="yesnoTypes"
                                   [(ngModel)]="physicalWelcome"
                                   (onSelect)="selectPhysical($event)"
                                   (onDeSelect)="unSelectPhysical($event)"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>



      <!--<div class="y3-flex-row y3-flex-justify-content-center y3-margin-top-16 y3-color-green-vogue">
        <div class="y3-XLarge y3-roboto y3-margin-bottom-16 y3-bold">{{'g11-online-checkin.first-step' | translate}}</div>
      </div>-->



      <div *ngIf="!property.guestNotAllowedToChangeTime" class="y3-flex-responsive y3-full-width y3-margin-bottom-8 y3-margin-top-16" >
        <div class="y3-flex-column y3-X-small g11-input-margin" >
          <div class="y3-flex-column y3-X-small g11-input-time" >
            <div>{{'g10-pre-checkin.check-in-time' | translate}}</div>
            <input class="y3-margin-top-4 y3-input-text-small " type="time" autocomplete="none" [(ngModel)]="onlineCheckIn.onLineCheckInTime">
          </div>
          <div class="y3-X-small y3-color-scorpion y3-margin-top-8" *ngIf="property?.defaultCheckInTime">{{'guest-setup-page.explain-check-in' | translate}} <span >({{property.defaultCheckInTime}})</span></div>
          <div *ngIf="property.defaultMaxCheckInTime" class="y3-X-small y3-color-scorpion y3-margin-top-8" >{{'guest-setup-page.explain-max-check-in' | translate}} <span >({{property.defaultMaxCheckInTime}})</span></div>
        </div>
        <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input" >
          <div class="y3-flex-column y3-X-small g11-input-time" >
            <div>{{'g10-pre-checkin.check-out-time' | translate}}</div>
            <input  class="y3-margin-top-4 y3-input-text-small " type="time" autocomplete="none" [(ngModel)]="onlineCheckIn.onLineCheckOutTime">
          </div>
          <div class="y3-X-small y3-color-scorpion y3-margin-top-8" *ngIf="property?.defaultCheckOutTime">{{'guest-setup-page.explain-check-out' | translate}} <span >({{property.defaultCheckOutTime}})</span></div>
        </div>
      </div>

      <div *ngIf="property.checkInOnlineWithFlightInfo">
        <div class="y3-Medium y3-margin-top-16 y3-bold y3-title-colors y3-margin-bottom-8">{{'g11-online-checkin.your-flight-info' | translate}} </div>
        <div class="y3-flex-responsive">
          <div class="y3-flex-column y3-X-small g11-input-margin" >
            <div class="y3-flex-column y3-X-small g11-input-time" >
              <div>{{'g11-online-checkin.arrival-flight-number' | translate}}</div>
              <input class="y3-margin-top-4 y3-input-text-small " type="text" autocomplete="none" [(ngModel)]="onlineCheckIn.planeArrivalFlightNumber">
            </div>
          </div>
          <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input" >
            <div class="y3-flex-column y3-X-small g11-input-time" >
              <div>{{'g11-online-checkin.arrival-flight-time' | translate}}</div>
              <input class="y3-margin-top-4 y3-input-text-small " type="time" autocomplete="none" [(ngModel)]="onlineCheckIn.planeArrivalTime">
            </div>
          </div>
        </div>

        <div class="y3-flex-responsive y3-margin-top-8">
          <div class="y3-flex-column y3-X-small g11-input-margin" >
            <div class="y3-flex-column y3-X-small g11-input-time" >
              <div>{{'g11-online-checkin.departure-flight-number' | translate}}</div>
              <input class="y3-margin-top-4 y3-input-text-small " type="text" autocomplete="none" [(ngModel)]="onlineCheckIn.planeDepartureFlightNumber">
            </div>
          </div>
          <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input" >
            <div class="y3-flex-column y3-X-small g11-input-time" >
              <div>{{'g11-online-checkin.departure-flight-time' | translate}}</div>
              <input class="y3-margin-top-4 y3-input-text-small " type="time" autocomplete="none" [(ngModel)]="onlineCheckIn.planeDepartureTime">
            </div>
          </div>
        </div>

      </div>

      <div  class="y3-Medium y3-margin-top-16 y3-bold y3-margin-bottom-8 y3-title-colors">{{'g11-online-checkin.guest-information' | translate}}</div>

      <div id="nombre de personnes" class="y3-flex-row " style="flex-flow:  wrap">
        <div class="squares-container">
          <p class="y3-Small">{{'guest-setup-page.adults' | translate}}</p>

          <!-- <div class="square coming border-sep  gris-background">
            <div class="minus" (click)="removeType('adult')">-</div>
            <div class="result">{{adult}}</div>
            <div class="plus" (click)="AddType('adult')">+</div>
          </div> -->
          <div class="bulle-container" style="margin-right: 24px;">
            <div (click)="removeAdult()" class="clickable"  [ngClass]="checkDisableBtnDecre(onlineCheckIn.adults)">
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.adults}}</span>
            </div>
            <div (click)="addAdult()" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>
        <div class="squares-container" style="margin-right: 24px;">
          <p class="y3-Small">{{'guest-setup-page.children' | translate}}</p>
          <!-- <div class="square coming border-sep  gris-background">
            <div class="minus" (click)="removeType('children')">-</div>
            <div class="result">{{children}}</div>
            <div class="plus" (click)="AddType('children')">+</div>
          </div> -->
          <div class="bulle-container">
            <div (click)="removeChild()" class="clickable" [ngClass]="checkDisableBtnDecre(onlineCheckIn.children)" >
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.children}}</span>
            </div>
            <div (click)="addChild()" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>
        <div class="squares-container" style="margin-right: 24px;">
          <p class="y3-Small">{{'guest-setup-page.infants' | translate}}</p>
          <!-- <div class="square coming border-sep  gris-background mrg-r-0">
            <div class="minus" (click)="removeType('infants')">-</div>
            <div class="result">{{infants}}</div>
            <div class="plus" (click)="AddType('infants')">+</div>
          </div> -->
          <div class="bulle-container">
            <div (click)="removeBaby()" class="clickable" [ngClass]="checkDisableBtnDecre(onlineCheckIn.babies)" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.babies}}</span>
            </div>
            <div (click)="addBaby()" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>

        <div class="squares-container" *ngIf="property?.allowPets">
          <p class="y3-Small">{{'guest-setup-page.pets' | translate}}</p>
          <div class="bulle-container">
            <div (click)="removePets()" class="clickable" [ngClass]="checkDisableBtnDecre(onlineCheckIn.pets)" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.pets}}</span>
            </div>
            <div (click)="addPets()" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!property.noshowBedsDetail" class="y3-Medium y3-margin-top-16 y3-bold y3-margin-bottom-8 y3-title-colors">{{'g11-online-checkin.beds-information' | translate}}</div>

      <div *ngIf="!property.noshowBedsDetail" class="y3-flex-row " style="flex-flow:  wrap">
        <div class="squares-container" style="margin-right: 24px;">
          <p class="y3-Small">{{'g11-online-checkin.double' | translate}}</p>
          <div class="bulle-container">
            <div (click)="remove('double')" class="clickable" [ngClass]="checkDisableBtnDecre(onlineCheckIn.doubleBeds)" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.doubleBeds}}</span>
            </div>
            <div (click)="add('double')" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>

        <div class="squares-container" style="margin-right: 24px;">
          <p class="y3-Small">{{'g11-online-checkin.single' | translate}}</p>
          <div class="bulle-container">
            <div (click)="remove('single')" class="clickable" [ngClass]="checkDisableBtnDecre(onlineCheckIn.singleBeds)" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.singleBeds}}</span>
            </div>
            <div (click)="add('single')" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>

        <div class="squares-container" style="margin-right: 24px;">
          <p class="y3-Small">{{'g11-online-checkin.sofa' | translate}}</p>
          <div class="bulle-container">
            <div (click)="remove('sofa')" class="clickable" [ngClass]="checkDisableBtnDecre(onlineCheckIn.sofaBeds)" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.sofaBeds}}</span>
            </div>
            <div (click)="add('sofa')" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>


      </div>

      <div *ngIf="property.parkings > 0" class="y3-Medium y3-margin-top-16 y3-bold y3-margin-bottom-8 y3-title-colors">{{'g11-online-checkin.parkings-information' | translate}}</div>

      <div *ngIf="property.parkings> 0" class="y3-flex-row " style="flex-flow:  wrap">
        <div class="squares-container" style="margin-right: 24px;">
          <p class="y3-Small">{{'g11-online-checkin.parkings' | translate}}</p>
          <div class="bulle-container">
            <div (click)="remove('parkings')" class="clickable" [ngClass]="checkDisableBtnDecre(onlineCheckIn.parkings)" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/below.svg" alt="below-disabled"/>-->
            </div>
            <div class="y3-X-small g11-value">
              <span>{{onlineCheckIn.parkings}}</span>
            </div>
            <div (click)="add('parkings')" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
              <!--<img class="g11-add-minus-button" src="assets/icon/check/plus.svg" alt="add-time"/>-->
            </div>
          </div>
        </div>

      </div>


      <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-Medium y3-margin-top-16 y3-bold y3-title-colors y3-margin-bottom-4">{{'g10-pre-checkin.personal-info' | translate}}</div>

      <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-flex-responsive y3-margin-bottom-8" >
        <div class="y3-flex-column y3-X-small g11-input-margin">
          <div>{{'g10-pre-checkin.firstname' | translate}}</div>
          <input  class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="onlineCheckIn.firstName" />
        </div>
        <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input">
          <div>{{'g10-pre-checkin.lastname' | translate}}</div>
          <input  class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="onlineCheckIn.lastName" />
        </div>
      </div>

      <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-flex-responsive y3-margin-bottom-8 " >
        <!-- Decision don't show the existing email ... <div class="y3-flex-column y3-X-small g11-input-margin" >
          <div>{{'g10-pre-checkin.email' | translate}}</div>
          <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="onlineCheckIn.email" />
        </div>-->
        <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input" >
          <div>{{'g10-pre-checkin.personal-email' | translate}}</div>
          <input  class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="onlineCheckIn.personalEmail" />
        </div>
      </div>

      <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-flex-responsive y3-margin-bottom-8" >
        <div class="y3-flex-column y3-X-small g11-input-margin" >
          <div>{{'g10-pre-checkin.phone' | translate}}</div>
          <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="onlineCheckIn.phoneNumber" />
        </div>
        <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input" >
          <div>{{'g10-pre-checkin.secondary-phone' | translate}}</div>
          <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="onlineCheckIn.secondaryPhoneNumber" />
        </div>
      </div>

      <div class="y3-flex-responsive y3-full-width y3-margin-bottom-8 y3-margin-top-16"  *ngIf="property.checkInOnline">
        <div class="y3-flex-column y3-X-small g11-input-margin" >
          <div class="y3-flex-column y3-X-small g11-input-time" >
            <div>{{'g11-online-checkin.birth-date' | translate}}</div>
            <input class="y3-margin-top-4 y3-input-text-small " type="date" autocomplete="none" [(ngModel)]="onlineCheckIn.guestBirthDate">
          </div>
        </div>
        <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input" >
          <div>{{'g11-online-checkin.country' | translate}}</div>
          <ng-multiselect-dropdown class="" [placeholder]="'g11-online-checkin.choose-country' | translate"
                                   [settings]="dropdownSettings"
                                   [data]="countriesWithName"
                                   [(ngModel)]="guestCountrySelected"
                                   (onSelect)="selectCountry($event)"
                                   (onDeSelect)="deSelectCountry($event)"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>


      <!--<div class="y3-flex-row y3-flex-justify-content-center y3-margin-top-16 y3-color-green-vogue">
        <div class="y3-XLarge y3-roboto y3-margin-bottom-16 y3-bold">{{'g11-online-checkin.second-step' | translate}}</div>
      </div>-->




      <div *ngIf="property.checkInOnline && !property.checkinOnlineWithNoIdentityProof
      && ((onlineCheckIn.checkStatus === 'VALIDATED' && onlineCheckIn.identityProofUrls?.length > 0) || (onlineCheckIn.checkStatus !== 'SKIPPED' && onlineCheckIn.checkStatus !== 'VALIDATED')) ">
        <div class="y3-Medium y3-bold y3-margin-top-16 y3-title-colors">{{'g11-online-checkin.personal-proofofidentity' | translate}}</div>

        <div class="y3-flex-column y3-margin-top-16 y3-X-small" >
          <div class="y3-Small " *ngFor="let document of onlineCheckIn.identityProofUrls">
            <div class="y3-margin-bottom-8 ">
              <a href="{{document}}" target="_blank">{{getDocumentName(document)}}</a>
            </div>
          </div>
        </div>

        <div class="g11-upload" *ngIf="onlineCheckIn.checkStatus !== 'VALIDATED' && onlineCheckIn.checkStatus !== 'SKIPPED' && currentMode === 'STEP_EDIT'">
          <app-shared-upload (docUploaded)="onIdProofUpload($event)" [allowedTypes]="allowedImagesDocs"
                             [btnLabel]="'g11-online-checkin.upload'" [uploadType]="'multiple'"></app-shared-upload>
        </div>
      </div>


      <div id="card-element" class="field"></div>


      <section *ngIf="property.checkInOnlineWithFichePolice">
        <div class="y3-Medium y3-bold y3-margin-top-16 y3-title-colors" *ngIf="guestIdentityDetailList?.length > 0">{{'g11-online-checkin.guests-identity' | translate}}</div>
        <div  class="y3-X-small y3-color-scorpion y3-margin-top-8" *ngIf="guestIdentityDetailList?.length > 0">{{'g11-online-checkin.guests-identity-descr-' + onLineCheckCountry | translate}}</div>

        <div *ngFor="let extraguest of guestIdentityDetailList; let guestIndex = index" class="g11-guest-card">

          <div class="y3-Medium y3-bold y3-margin-bottom-8">{{'g11-online-checkin.guest-list' | translate}} {{guestIndex + 2}}</div>

          <div class="y3-flex-responsive y3-margin-bottom-8" >
            <div class="y3-flex-column y3-X-small g11-input-margin">
              <div>{{'g11-online-checkin.firstname' | translate}}</div>
              <input  class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="extraguest.firstName" />
            </div>
            <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input">
              <div>{{'g11-online-checkin.lastname' | translate}}</div>
              <input  class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="extraguest.lastName" />
            </div>
          </div>

          <div class="y3-flex-responsive y3-margin-bottom-8" >
            <div class="y3-flex-column y3-X-small g11-input-margin">
              <div>{{'g11-online-checkin.email' | translate}}</div>
              <input  class="y3-input-text-small y3-margin-top-4" type="email" [(ngModel)]="extraguest.email" />
            </div>
            <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input">
              <div>{{'g11-online-checkin.phone' | translate}}</div>
              <input  class="y3-input-text-small y3-margin-top-4" type="tel" [(ngModel)]="extraguest.phone" />
            </div>
          </div>

          <div class="y3-flex-responsive y3-margin-bottom-8" >
            <div class="y3-flex-column y3-X-small g11-input-margin" >
              <div class="y3-flex-column y3-X-small g11-input-time" >
                <div>{{'g11-online-checkin.date-of-birth' | translate}}</div>
                <input class="y3-margin-top-4 y3-input-text-small " type="date" autocomplete="none" [(ngModel)]="extraguest.birthDate">
              </div>
            </div>
            <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input">
              <div>{{'g11-online-checkin.city-of-birth' | translate}}</div>
              <input  class="y3-input-text-small y3-margin-top-4" type="tel" [(ngModel)]="extraguest.cityOfBirth" />
            </div>

          </div>

          <div class="y3-flex-responsive y3-margin-bottom-8" >
            <div class="y3-flex-column y3-X-small g11-input-margin">
              <div>{{'g11-online-checkin.permanent-address' | translate}}</div>
              <input  class="y3-input-text-small y3-margin-top-4" type="tel" [(ngModel)]="extraguest.homeFullAddress" />
            </div>
          </div>

        </div>
      </section>


      <div *ngIf="property?.checkinOnlineWithContract" >
        <div class="y3-Medium y3-margin-top-16 y3-bold y3-title-colors">{{'g11-online-checkin.rental-contract' | translate}}</div>

        <div *ngIf="!onlineCheckIn.contractDocumentUrl && onlineCheckIn.checkStatus !== 'VALIDATED' && onlineCheckIn.checkStatus !== 'SKIPPED'" class="y3-margin-top-8">
          <app-button [type]="'secondary-x-small'" text="{{'g11-online-checkin.generate-contract' | translate}}" (debounceClick)="generateContract()"></app-button>
        </div>

        <div class="y3-margin-top-16 y3-margin-bottom-8 y3-Small" *ngIf="onlineCheckIn.contractDocumentUrl && !onlineCheckIn.contractDocumentUrlSigned">
          <a href="{{onlineCheckIn.contractDocumentUrl}}" target="_blank">{{'g11-online-checkin.read-contract-document' | translate}}</a>
        </div>

        <div class="y3-margin-bottom-8 y3-margin-top-16 y3-X-small" *ngIf="onlineCheckIn.contractDocumentUrlSigned">
          <a href="{{onlineCheckIn.contractDocumentUrlSigned}}" target="_blank">{{'g11-online-checkin.contract-signed' | translate}}</a>
        </div>

        <div *ngIf="onlineCheckIn.checkStatus !== 'VALIDATED' && onlineCheckIn.checkStatus !== 'SKIPPED' ">
          <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-margin-top-16 y3-title-colors" >
            <div class="y3-Medium  y3-bold">{{'g11-online-checkin.signature' | translate}}</div>
            <div>
              <app-button [type]="'ternary'" text="{{'g11-online-checkin.clear-signature' | translate}}" (debounceClick)="clearCanvas()"></app-button>
            </div>
          </div>

          <!-- SIGNATURE -->
          <div class="y3-margin-top-8 signature-pad" id="signature-pad">
            <div class="signature-pad--body" (click)="resizeCanvas()">
              <canvas id="canvasIDForSignature" style="width: 100%; height: 180px"></canvas>
            </div>
          </div>
        </div>


      </div>



      <div class="" *ngIf="property?.checkinOnlineWithCgv && onlineCheckIn.cgvDocumentUrl">

        <div class="y3-margin-top-16 y3-Medium y3-bold y3-title-colors">{{'g11-online-checkin.cgv-document' | translate}}</div>



        <div class="y3-margin-top-16 y3-margin-bottom-8 y3-Small">
          <a href="{{onlineCheckIn.cgvDocumentUrl}}" target="_blank">{{'g11-online-checkin.read-cgv-document' | translate}}</a>
        </div>

        <div *ngIf="onlineCheckIn.checkStatus !== 'VALIDATED' && onlineCheckIn.checkStatus !== 'SKIPPED' ">
          <mat-checkbox class="y3-flex-row y3-flex-align-center" style="white-space: normal" [(ngModel)]="localCgvValidated">
            <div class="y3-X-small "> {{ "online-check.confirm-cgv" | translate }}</div>
          </mat-checkbox>
        </div>
      </div>


      <div *ngIf="!property.noShowParticularRequest ">
        <div class="y3-Medium y3-margin-top-16 y3-bold y3-margin-bottom-8 y3-title-colors">{{'g11-online-checkin.guest-note' | translate}}</div>
        <div *ngIf="corporate.lightCheckInOnLineWording" class="y3-X-small y3-margin-bottom-8" [innerHTML]="corporate.lightCheckInOnLineWording"></div>
        <textarea [rows]="10"  class="y3-input-text-small" style="width: 100%; border: unset" [(ngModel)]="onlineCheckIn.guestNote"></textarea>
      </div>

      <div class=" y3-margin-top-16  " *ngIf="currentMode==='STEP_EDIT' && isDesktopMode()">
        <app-button [type]="'primary'" [text]="'g11-online-checkin.validate' | translate" (debounceClick)="validate()"></app-button>
      </div>


    </section>

    <section id="STEP_IDENTIFICATION" *ngIf="currentStepName === StepName.STEP_IDENTIFICATION">





      <div class="y3-margin-top-16">
        <app-button [type]="'primary-small'" [text]="'g11-online-checkin.next' | translate" (debounceClick)="nextStep()"></app-button>
      </div>

    </section>

    <section id="STEP_CONTRACT" *ngIf="currentStepName === StepName.STEP_CONTRACT">
      <div class="y3-flex-row y3-flex-justify-content-center y3-margin-top-16 y3-color-green-vogue">
        <div class="y3-XLarge y3-roboto y3-margin-bottom-16 y3-bold">{{'g11-online-checkin.third-step' | translate}}</div>
      </div>

      <div class="y3-flex-row y3-flex-justify-content-end y3-margin-top-16">
        <app-button [type]="'primary'" [text]="'g11-online-checkin.validate' | translate" (debounceClick)="nextStep()"></app-button>
      </div>

    </section>

    <section id="STEP_RECAP" *ngIf="currentStepName === StepName.STEP_RECAP">
      <div style="padding: 8px 16px">


        <div class="y3-X-small y3-margin-top-8">
          {{'g11-online-checkin.done-by-guest' | translate}} <strong>{{utilsService.formatDate(onlineCheckIn.onlineCheckGuestSendingTime)}}</strong>
        </div>

        <div class="y3-X-small y3-margin-top-8" *ngIf="onlineCheckIn.onlineCheckGuestApprovalTime">
          {{'g11-online-checkin.validation-date' | translate}} <strong>{{utilsService.formatDate(onlineCheckIn.onlineCheckGuestApprovalTime)}}</strong>
        </div>

        <div class="y3-flex-row y3-margin-top-16" >
          <div class="y3-flex-column y3-X-small " style="width: 50%; margin-right: 8px;">
            <div>{{'g11-online-checkin.check-in-time' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.onLineCheckInTime}}</div>
          </div>
          <div class="y3-flex-column y3-X-small " style="width: 50%">
            <div>{{'g11-online-checkin.check-out-time' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.onLineCheckOutTime}}</div>
          </div>
        </div>

        <div class="y3-flex-row y3-margin-top-16" >
          <div class="y3-flex-column y3-X-small " style="width: 50%; margin-right: 8px;">
            <div>{{'g11-online-checkin.firstname' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.firstName}}</div>
          </div>
          <div class="y3-flex-column y3-X-small " style="width: 50%">
            <div>{{'g11-online-checkin.lastname' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.lastName}}</div>
          </div>
        </div>

        <div class="y3-flex-row y3-margin-top-16" >
          <div class="y3-flex-column y3-X-small " style="width: 50%; margin-right: 8px;">
            <div>{{'g11-online-checkin.gender' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.guestGender | titlecase}}</div>
          </div>
          <div class="y3-flex-column y3-X-small " style="width: 50%">
            <div>{{'g11-online-checkin.birthdate' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.guestBirthDate}}</div>
          </div>
        </div>

        <div class="y3-flex-row y3-margin-top-16" >
          <div class="y3-flex-column y3-X-small " style="width: 50%; margin-right: 8px;">
            <div>{{'g11-online-checkin.country' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.guestCountry}}</div>
          </div>
          <div class="y3-flex-column y3-X-small " style="width: 50%">
            <div>{{'g11-online-checkin.language' | translate}}</div>
            <div class="y3-label-text-small y3-margin-top-4">{{utilsService.getLanguageNameFromCode(onlineCheckIn.guestLanguage)}}</div>
          </div>
        </div>

        <div class="y3-yaago-title-page y3-margin-top-16">{{'g11-online-checkin.rental-contract' | translate}}</div>

        <div class="y3-flex-column y3-margin-top-16 y3-X-small" *ngIf="onlineCheckIn.contractDocumentUrlSigned">
          <div class="y3-margin-bottom-0 ">
            <a href="{{onlineCheckIn.contractDocumentUrlSigned}}" target="_blank">{{'g11-online-checkin.contract-signed' | translate}}</a>
          </div>
        </div>

        <div class="y3-yaago-title-page y3-margin-top-16">{{'g11-online-checkin.personal-proofofidentity' | translate}}</div>

        <div class="y3-flex-column y3-margin-top-16 y3-X-small" >
          <div class="y3-X-small " *ngFor="let document of onlineCheckIn.identityProofUrls">
            <div class="y3-margin-bottom-8 ">
              <a href="{{document}}" target="_blank">{{getDocumentName(document)}}</a>
            </div>
          </div>
        </div>

        <div class="y3-flex-row y3-margin-top-16" *ngIf="onlineCheckIn.reasonRefused">
          <div class="y3-flex-column y3-X-small y3-full-width">
            <div>{{'g11-online-checkin.refused-reason' | translate}} :</div>
            <div class="y3-label-text-small y3-margin-top-4">{{onlineCheckIn.reasonRefused}}</div>
          </div>
        </div>

      </div>
    </section>

  </div>

  <div class="y3-empty-space"></div>

</div>

<div class=" y3-padding-8 y3-fixed-bottom " *ngIf="currentMode==='STEP_EDIT' && !isDesktopMode()">
  <app-button [type]="'primary'" [text]="'g11-online-checkin.validate' | translate" (debounceClick)="validate()"></app-button>
</div>

