import {AfterContentChecked, AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {UtilsService} from '../../../core/services/utils.service';
import {GuestService} from '../../../core/services/guest.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {LanguageManagementService} from '../../../core/services/language.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {takeUntil} from 'rxjs/operators';
import {OnlineCheckin} from '../../../models/guestview/OnlineCheckin';
import {ButtonType} from '../../../shared/components/button/button.component';
import {PaysService} from '../../../core/services/pays.service';
import {DocumentUploadTypes, YaagoDocument} from '../../../models/YaagoDocument';
import {Location} from '@angular/common';
import {ApiService} from '../../../core/http/api.service';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import SignaturePad from 'signature_pad';
import {LoaderService} from '../../../core/services/LoaderService';
import {DocumentService} from '../../../core/services/DocumentService';
import * as moment from 'moment/moment';
import {GuestIdentityDetails} from '../../../models/GuestIdentityDetails';


@Component({
  selector: 'app-g11-online-check-in',
  templateUrl: './g11-online-check-in.component.html',
  styleUrls: ['./g11-online-check-in.component.scss']
})
export class G11OnlineCheckInComponent extends AbstractWindow implements OnInit, AfterViewInit, AfterContentInit {

  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  urlLang: string;
  public currentStepName : string = StepName.STEP_INFORMATION;
  public currentMode : string = 'STEP_EDIT';
  allowedImagesDocs = DocumentUploadTypes.images().concat(DocumentUploadTypes.pdf());
  StepName = StepName;
  ButtonType = ButtonType;
  countries = [];
  countriesWithName = [];
  stayTypes: TypeOfStay[] = [];
  interestTypes: TypeOfStay[] = [];
  transportTypes: TypeOfStay[] = [];
  yesnoTypes = [];
  dropdownList = [];
  dropdownSettings = {};
  dropdownSettingsMultiple = {};
  dropdownSettingsNoFilter = {};
  onLineCheckCountry: string = 'fr';

  onlineCheckIn = new OnlineCheckin();
  localCgvValidated = false;
  stayType: any;
  stayInterest: any;
  stayTransport: any;
  physicalWelcome: any;
  guestCountrySelected = [];
  errors = [];

  signaturePad: any;
  canvasID: any;
  cardElement: any;

  guestIdentityDetailList: GuestIdentityDetails[] = [];


  constructor(
              public utilsService: UtilsService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              public paysService: PaysService,
              public translateService: TranslateService,
              private snackbarService: SharedSnackbarService,
              private languageService: LanguageManagementService,
              private apiService: ApiService,
              private documentService: DocumentService,
              private location: Location,
              private router: Router,
              private route: ActivatedRoute) {
    super(utilsService);
  }

  ngOnInit() {


    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.dropdownSettingsMultiple = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.dropdownSettingsNoFilter = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };





    this.paysService
      .getPays(this.languageService.getLanguageInUrl())
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res) => {
        this.countries = res;
        this.countries.sort((a, b) => a.name.common.localeCompare(b.name.common));

        this.countriesWithName = [];

        this.countries.forEach( c => {
          let country = new Country();
          country.id = c.cca2;
          country.name = c.name.common;
          this.countriesWithName.push(country);
        });
      });
    this.route.queryParamMap.subscribe(params => {
      if (params[`params`].lang) {
        this.urlLang = params[`params`].lang;
        console.log("---> language :", this.urlLang);
      }
    });

    this.route.paramMap.subscribe( ParamMap => {
      const bookingId = ParamMap.get('bookingId');
      console.log("bookingId", bookingId);
      this.guestService.getPropertyAndBookingForGuest(bookingId , this.languageService.getLanguageInUrl()).pipe(takeUntil(this.onDestroy)).subscribe( res => {
        console.log("---> PropertyAndBooking:", res);
        this.booking = res.booking;
        this.corporate = res.corporate;

        if (this.corporate && this.corporate.onLineCheckCountry) {
          this.onLineCheckCountry = this.corporate.onLineCheckCountry;
        }


        this.property = res.property;
        this.onlineCheckIn = this.booking.onLineCheck;

        console.log('----> onlineCheckIn', this.onlineCheckIn);
        if (this.onlineCheckIn == null) {
          this.onlineCheckIn = new OnlineCheckin();



          this.onlineCheckIn.propertyBookingId = this.booking.id;
          this.onlineCheckIn.propertyId = this.property.id;

          this.onlineCheckIn.firstName = this.booking.guests[0].firstName;
          this.onlineCheckIn.lastName = this.booking.guests[0].lastName;
          this.onlineCheckIn.onLineCheckInTime = this.property.defaultCheckInTime;
          this.onlineCheckIn.onLineCheckOutTime = this.property.defaultCheckOutTime;
          this.onlineCheckIn.phoneNumber = this.booking.guests[0].phone;
          this.onlineCheckIn.secondaryPhoneNumber = this.booking.guests[0].secondaryPhone;

          this.onlineCheckIn.onLineCheckInTime = this.booking.expectedCheckinLocalTime;
          this.onlineCheckIn.onLineCheckOutTime = this.booking.expectedCheckoutLocalTime;



          this.onlineCheckIn.adults = this.booking.adults;
          this.onlineCheckIn.pets = this.booking.pets;
          this.onlineCheckIn.babies = this.booking.babies;
          this.onlineCheckIn.children = this.booking.children;

          this.onlineCheckIn.singleBeds = this.booking.singleBeds;
          this.onlineCheckIn.doubleBeds = this.booking.doubleBeds;
          this.onlineCheckIn.sofaBeds = this.booking.sofaBeds;
          this.onlineCheckIn.babyBeds = this.booking.babyBeds;
          this.onlineCheckIn.parkings = this.booking.parkings;


        } else {

          if (!this.onlineCheckIn.onLineCheckInTime) {
            this.onlineCheckIn.onLineCheckInTime = this.booking.expectedCheckinLocalTime;
          }
          if (!this.onlineCheckIn.onLineCheckOutTime) {
            this.onlineCheckIn.onLineCheckOutTime = this.booking.expectedCheckoutLocalTime;
          }

          if (this.onlineCheckIn.adults == null) { this.onlineCheckIn.adults = this.booking.adults; }
          if (this.onlineCheckIn.children == null) { this.onlineCheckIn.children = this.booking.children; }
          if (this.onlineCheckIn.babies == null) { this.onlineCheckIn.babies = this.booking.babies; }
          if (this.onlineCheckIn.pets == null) { this.onlineCheckIn.pets = this.booking.pets; }
          if (this.onlineCheckIn.doubleBeds == null) { this.onlineCheckIn.doubleBeds = this.booking.doubleBeds; }
          if (this.onlineCheckIn.singleBeds == null) { this.onlineCheckIn.singleBeds = this.booking.singleBeds; }
          if (this.onlineCheckIn.sofaBeds == null) { this.onlineCheckIn.sofaBeds = this.booking.sofaBeds; }
          if (this.onlineCheckIn.babyBeds == null) { this.onlineCheckIn.babyBeds = this.booking.babyBeds; }
          if (this.onlineCheckIn.parkings == null) { this.onlineCheckIn.parkings = this.booking.parkings; }

          if (this.onlineCheckIn.checkStatus === 'WAITING' || this.onlineCheckIn.checkStatus === 'NOUVEAU' || this.onlineCheckIn.checkStatus === 'VALIDATED') {
            console.log('----> onlineCheckIn NOUVEAU WAITING');
            this.currentMode = 'STEP_NON_EDIT';
          }
        }

        if (this.onlineCheckIn.adults == null) { this.onlineCheckIn.adults = 0; }
        if (this.onlineCheckIn.children == null) { this.onlineCheckIn.children = 0; }
        if (this.onlineCheckIn.babies == null) { this.onlineCheckIn.babies = 0; }
        if (this.onlineCheckIn.pets == null) { this.onlineCheckIn.pets = 0; }
        if (this.onlineCheckIn.doubleBeds == null) { this.onlineCheckIn.doubleBeds = 0; }
        if (this.onlineCheckIn.singleBeds == null) { this.onlineCheckIn.singleBeds = 0; }
        if (this.onlineCheckIn.sofaBeds == null) { this.onlineCheckIn.sofaBeds = 0; }
        if (this.onlineCheckIn.babyBeds == null) { this.onlineCheckIn.babyBeds = 0; }
        if (this.onlineCheckIn.parkings == null) { this.onlineCheckIn.parkings = 0; }

        for (let iAdults=1; iAdults<= this.onlineCheckIn.adults -1; iAdults++) {
          this.guestIdentityDetailList.push(new GuestIdentityDetails())
        }

        /*if (this.onlineCheckIn.checkStatus === 'VALIDATED' && this.property.checkInOnline) {
          this.currentStepName = StepName.STEP_RECAP;
        }*/

        let bookletLanguage = this.urlLang;
        if (bookletLanguage == null) {
          this.urlLang = this.translateService.getBrowserLang();
          if (this.booking && this.booking.guests != null && this.booking.guests.length > 0
            && this.booking.guests[0].languages != null && this.booking.guests[0].languages.length > 0) {
            bookletLanguage = this.booking.guests[0].languages[0];
          }
          if (this.booking == null) {
            bookletLanguage = this.translateService.getBrowserLang();
          }
        }

        bookletLanguage = UtilsService.isLanguageSupported(bookletLanguage);
        this.languageService.setLanguageInUrl(bookletLanguage);
        this.translateService.use(bookletLanguage);

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {

          this.stayTypes = [
            { id: 'business', name: this.translateService.instant('g11-online-checkin.business') },
            { id: 'leisure', name: this.translateService.instant('g11-online-checkin.leisure') },
            { id: 'family', name: this.translateService.instant('g11-online-checkin.family') },
            { id: 'romantic', name: this.translateService.instant('g11-online-checkin.romantic') },
            { id: 'honeymoon', name: this.translateService.instant('g11-online-checkin.honey-moon') }
          ];

          this.yesnoTypes = [
            { id: 'yes', name: this.translateService.instant('g11-online-checkin.yes') },
            { id: 'no', name: this.translateService.instant('g11-online-checkin.no') }
          ]
          this.interestTypes = [
            { id: 'walk', name: this.translateService.instant('g11-online-checkin.walk') },
            { id: 'kids', name: this.translateService.instant('g11-online-checkin.kids') },
            { id: 'monuments', name: this.translateService.instant('g11-online-checkin.monuments') },
            { id: 'restaurants', name: this.translateService.instant('g11-online-checkin.restaurants') },
            { id: 'sport', name: this.translateService.instant('g11-online-checkin.sport') }

          ];

          this.transportTypes = [
            { id: 'train', name: this.translateService.instant('g11-online-checkin.train') },
            { id: 'plane', name: this.translateService.instant('g11-online-checkin.plane') },
            { id: 'boat', name: this.translateService.instant('g11-online-checkin.boat') },
            { id: 'car', name: this.translateService.instant('g11-online-checkin.car') }
          ];

        });

        this.stayTypes = [
          { id: 'business', name: this.translateService.instant('g11-online-checkin.business') },
          { id: 'leisure', name: this.translateService.instant('g11-online-checkin.leisure') },
          { id: 'family', name: this.translateService.instant('g11-online-checkin.family') },
          { id: 'romantic', name: this.translateService.instant('g11-online-checkin.romantic') },
          { id: 'honeymoon', name: this.translateService.instant('g11-online-checkin.honey-moon') }
        ];

        this.yesnoTypes = [
          { id: 'yes', name: this.translateService.instant('g11-online-checkin.yes') },
          { id: 'no', name: this.translateService.instant('g11-online-checkin.no') }
        ]
        this.interestTypes = [
          { id: 'walk', name: this.translateService.instant('g11-online-checkin.walk') },
          { id: 'kids', name: this.translateService.instant('g11-online-checkin.kids') },
          { id: 'monuments', name: this.translateService.instant('g11-online-checkin.monuments') },
          { id: 'restaurants', name: this.translateService.instant('g11-online-checkin.restaurants') },
          { id: 'sport', name: this.translateService.instant('g11-online-checkin.sport') }

        ];

        this.transportTypes = [
          { id: 'train', name: this.translateService.instant('g11-online-checkin.train') },
          { id: 'plane', name: this.translateService.instant('g11-online-checkin.plane') },
          { id: 'boat', name: this.translateService.instant('g11-online-checkin.boat') },
          { id: 'car', name: this.translateService.instant('g11-online-checkin.car') }
        ];

        this.stayTransport = [];
        if (this.onlineCheckIn.stayTransport != null) {
          this.stayTransport.push(this.transportTypes.find( t => t.id === this.onlineCheckIn.stayTransport));
          console.log("this.stayTrasnport", this.stayTransport);
        }

        this.stayType = [];
        if (this.onlineCheckIn.stayType != null) {
          this.stayType.push(this.stayTypes.find( t => t.id === this.onlineCheckIn.stayType));
        }

        if (this.onlineCheckIn.guestCountry != null) {
          this.guestCountrySelected.push(this.countriesWithName.find( t => t.id === this.onlineCheckIn.guestCountry));
        }

        this.physicalWelcome = [];
        if (this.onlineCheckIn.physicalWelcome) {
          this.physicalWelcome.push(this.yesnoTypes.find( t => t.id === 'yes'));
        } else {
          this.physicalWelcome.push(this.yesnoTypes.find( t => t.id === 'no'));
        }

        this.stayInterest = [];
        if (this.onlineCheckIn.stayInterests != null) {
          this.onlineCheckIn.stayInterests.forEach( interest => {
            this.stayInterest.push(this.interestTypes.find( t => t.id === interest));
          });
        }







      });
    });
  }

  ngAfterViewInit(): void {

    this.canvasID = document.getElementById('canvasIDForSignature');
    console.log("this.canvasID", this.canvasID);
    window.addEventListener('resize', this.resizeCanvas);
    this.resizeCanvas();
  }
  ngAfterContentInit() {
    this.resizeCanvas();
  }
  nextStep() {
    if (this.currentStepName === StepName.STEP_INFORMATION) {
      this.currentStepName = StepName.STEP_IDENTIFICATION;
    } else if (this.currentStepName === StepName.STEP_IDENTIFICATION) {
      this.currentStepName = StepName.STEP_CONTRACT;
    }
  }
  selectInterest($event) {
    if (this.onlineCheckIn.stayInterests == null) { this.onlineCheckIn.stayInterests = [];}
    this.onlineCheckIn.stayInterests.push($event.id);
  }
  unSelectInterest($event) {
    if (this.onlineCheckIn.stayInterests.length > 0) {
      this.onlineCheckIn.stayInterests = this.utilsService.newRemoveFromIterable(this.onlineCheckIn.stayInterests, $event.id);
    }
  }
  selectType($event) {
    this.onlineCheckIn.stayType = $event.id;
  }
  unSelectType($event) {
    this.onlineCheckIn.stayType = null;
  }
  selectTransport($event) {
    this.onlineCheckIn.stayTransport = $event.id;
  }
  unSelectTransport($event) {
    this.onlineCheckIn.stayTransport = null;
  }
  selectPhysical($event) {
    this.onlineCheckIn.physicalWelcome = $event.id === "yes";
  }
  unSelectPhysical($event) {
    this.onlineCheckIn.physicalWelcome = false;
  }



  getDocumentName(document) {
    if (document == null) { return ''; }
    if (document.lastIndexOf('/') > 0) {
      return document.substring(document.lastIndexOf('/') + 1);
    } else {
      return document;
    }
  }
  select($event) {
    console.log("event", $event);
  }

  onIdProofUpload(doc: YaagoDocument) {
    if (!this.onlineCheckIn.identityProofUrls) { this.onlineCheckIn.identityProofUrls = []; }
    this.onlineCheckIn.identityProofUrls.push(doc.url);
  }
  onPhysicalWelcomeChange($event) {
    this.onlineCheckIn.physicalWelcome = $event;
  }

  removeAdult() {
    if (this.onlineCheckIn.adults > 0) {
      this.onlineCheckIn.adults--;
      this.guestIdentityDetailList.splice(this.guestIdentityDetailList.length-1, 1);
    }
  }

  checkDisableBtnDecre(value : number) {
    if (value<1 ) {
      return 'primary-not-valid';
    }
  }


  addAdult() {
    if (this.property.personCapacity > 0) {
      if (this.onlineCheckIn.adults + this.onlineCheckIn.children < this.property.personCapacity) {
        this.onlineCheckIn.adults++;
        this.guestIdentityDetailList.push(new GuestIdentityDetails());
      }
    } else {
      this.onlineCheckIn.adults++;
      this.guestIdentityDetailList.push(new GuestIdentityDetails());
    }

  }

  addChild() {
    if (this.property.personCapacity > 0) {
      if (this.onlineCheckIn.adults + this.onlineCheckIn.children < this.property.personCapacity) {
        this.onlineCheckIn.children++;
        this.guestIdentityDetailList.push(new GuestIdentityDetails());
      }
    } else {
      this.onlineCheckIn.children++;
      this.guestIdentityDetailList.push(new GuestIdentityDetails());
    }

  }

  removeChild() {
    if (this.onlineCheckIn.children > 0) {
      this.onlineCheckIn.children--;
      this.guestIdentityDetailList.splice(this.guestIdentityDetailList.length-1, 1);
    }
  }

  addBaby() {
    this.onlineCheckIn.babies++;
  }
  addPets() {
    this.onlineCheckIn.pets++;
  }
  removePets() {
    if (this.onlineCheckIn.pets>0) {
      this.onlineCheckIn.pets--;
    }
  }
  removeBaby() {
    if (this.onlineCheckIn.babies > 0) {
      this.onlineCheckIn.babies--;
    }
  }

  clearCanvas() {
    if (this.signaturePad != null) {
      this.signaturePad.clear();
      this.resizeCanvas()
    }
  }
  resizeCanvas(test?) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    if (this.canvasID == null) {
      this.canvasID = document.getElementById('canvasIDForSignature');
      console.log("this.canvasID in Resize", this.canvasID)
      if (this.canvasID) {

        this.signaturePad = new SignaturePad(this.canvasID);

        this.canvasID.width = this.canvasID.offsetWidth * ratio;

        if (this.isDesktopMode()) {
          this.canvasID.height = this.canvasID.offsetHeight * ratio;
        } else {
          this.canvasID.height = this.canvasID.offsetHeight * ratio + 200;
        }
        this.canvasID.getContext('2d').scale(ratio, ratio);
        this.clearCanvas();
      }


    }
  }


  add(type: string) {
    if (type === 'double' && this.onlineCheckIn.doubleBeds<this.property.doubleBeds) {
      this.onlineCheckIn.doubleBeds++;
    }
    if (type === 'single' && this.onlineCheckIn.singleBeds<this.property.singleBeds) {
      this.onlineCheckIn.singleBeds++;
    }
    if (type === 'sofa' && this.onlineCheckIn.sofaBeds<this.property.sofaBeds) {
      this.onlineCheckIn.sofaBeds++;
    }
    if (type === 'parkings' && this.onlineCheckIn.parkings<this.property.parkings) {
      this.onlineCheckIn.parkings++;
    }
    if (type === 'crib' && this.onlineCheckIn.babyBeds<this.property.babyBeds) {
      this.onlineCheckIn.babyBeds++;
    }
  }

  remove(type: string) {
    if (type === 'double' && this.onlineCheckIn.doubleBeds > 0) {
      this.onlineCheckIn.doubleBeds--;
    }
    if (type === 'single' && this.onlineCheckIn.singleBeds > 0) {
      this.onlineCheckIn.singleBeds--;
    }
    if (type === 'sofa' && this.onlineCheckIn.sofaBeds > 0) {
      this.onlineCheckIn.sofaBeds--;
    }
    if (type === 'parkings' && this.onlineCheckIn.parkings > 0) {
      this.onlineCheckIn.parkings--;
    }
    if (type === 'crib' && this.onlineCheckIn.babyBeds > 0) {
      this.onlineCheckIn.babyBeds--;
    }

  }

  checkDisableBtnIncre(value : number,type: string) {
    if (type === 'double' && value > this.property.doubleBeds-1) {
      return 'primary-not-valid';
    }
    if (type === 'single' && value > this.property.singleBeds-1) {
      return 'primary-not-valid';
    }
    if (type === 'sofa' && value > this.property.sofaBeds-1) {
      return 'primary-not-valid';
    }
    if (type === 'crib' && value > this.property.babyBeds-1) {
      return 'primary-not-valid';
    }
  }

  goBack() {
    this.location.back();
  }

  selectCountry($event) {
    this.onlineCheckIn.guestCountry = $event.id;
  }
  deSelectCountry($event) {
    this.onlineCheckIn.guestCountry = null;
  }

  cleanSignedUrl(url: string) {
    if (url.includes('?X-Goog-Algorithm')) {
      return url.substr(0, url.indexOf('?X-Goog-Algorithm'));
    }
    return url;
  }

  edit() {
    this.currentMode = 'STEP_EDIT';
    console.log('clikc on edit mode');
  }

  validate() {
    this.loaderService.showFullLoader('');
    if (!this.checkValidation()) {
      console.log('Not valid');
      this.loaderService.dismissLoader();
    } else {
      console.log('Valid');
      if (this.property.checkinOnlineWithContract && this.property.checkInOnline) {
        this.canvasID.toBlob( blob => {
          console.log(blob);
          const file = new File([blob], 'signature.JPG', {type: 'image/JPG'});
          const arrayFiles: File[] = Array.from([file]);
          this.documentService.uploadLinksGeneration(arrayFiles).subscribe(res => {
            const url = res[0].url;
            console.log('URL:', url);
            this.documentService.storageDirectUpload(res[0].url, arrayFiles[0]).subscribe(res => {
              console.log(res);

              this.onlineCheckIn.contractSignaturePictureUrl = this.cleanSignedUrl(url);
              this.onlineCheckIn.guestIdentityDetailList = this.guestIdentityDetailList;

              this.apiService.saveOnlineCheckin(this.onlineCheckIn).pipe(takeUntil(this.onDestroy)).subscribe(res => {
                this.loaderService.dismissLoader();
                this.onlineCheckIn = res;
                console.log("returned onlineCheckIn", this.onlineCheckIn);
                this.router.navigate(['/guest/' + this.booking.id ]).then( () => {
                  this.currentMode = 'STEP_NON_EDIT';
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
                });
              }, err => {
                this.loaderService.dismissLoader();
                this.utilsService.handleYaagoErrorCode(err)
              });

            });
          });
        });
      } else {
        this.apiService.saveOnlineCheckin(this.onlineCheckIn).pipe(takeUntil(this.onDestroy)).subscribe(res => {
          this.loaderService.dismissLoader();
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

          this.onlineCheckIn = res;
          console.log("returned onlineCheckIn", this.onlineCheckIn);
          this.router.navigate(['/guest/' + this.booking.id ]).then( () => {
            this.currentMode = 'STEP_NON_EDIT';
          });

        }, err => {
          this.loaderService.dismissLoader();
          this.utilsService.handleYaagoErrorCode(err)
        });
      }
    }
    // console.log("stayTransport", this.stayTransport);
    // console.log("returned onlineCheckIn", this.onlineCheckIn);
  }

  generateContract() {
    this.loaderService.showFullLoader('');
    this.apiService.createContract(this.booking.id).pipe(takeUntil(this.onDestroy)).subscribe(res => {
      this.loaderService.dismissLoader();
      this.onlineCheckIn.contractDocumentUrl = res.contractDocumentUrl;
      console.log("returned onlineCheckIn", this.onlineCheckIn);
    }, err => {
      this.loaderService.dismissLoader();
      this.utilsService.handleYaagoErrorCode(err)
    });
  }

  checkValidation(): boolean {
    this.errors = [];
    let validation = true;
    // PROOF OF IDS

    if (this.canvasID == null) {
      this.canvasID = document.getElementById('canvasIDForSignature');
      if (this.canvasID != null) {
        this.signaturePad = new SignaturePad(this.canvasID);
      }
    }

    if (!this.property.guestNotAllowedToChangeTime) {
      // Check the Time ...
      if (this.property.defaultCheckInTime && !this.property.canEarlyCheck) {
        if (moment(this.onlineCheckIn.onLineCheckInTime, 'hh:mm').isBefore(moment(this.property.defaultCheckInTime, 'hh:mm'))) {
          this.snackbarService.push(new SnackbarMessage(this.translateService.instant('online-check.impossible-checkin-time') + ' '
            + this.property.defaultCheckInTime, 'error'));
          return;
        }
      }
      if (this.property.defaultMaxCheckInTime && !this.property.canEarlyCheck) {
        if (moment(this.onlineCheckIn.onLineCheckInTime, 'hh:mm').isAfter(moment(this.property.defaultMaxCheckInTime, 'hh:mm'))) {
          this.snackbarService.push(new SnackbarMessage(this.translateService.instant('online-check.impossible-max-checkin-time') + ' '
            + this.property.defaultMaxCheckInTime, 'error'));
          return;
        }
      }

      if (this.property.defaultCheckOutTime && !this.property.canEarlyCheck) {
        if (moment(this.onlineCheckIn.onLineCheckOutTime, 'hh:mm').isAfter(moment(this.property.defaultCheckOutTime, 'hh:mm'))) {
          this.snackbarService.push(new SnackbarMessage(this.translateService.instant('online-check.impossible-checkout-time') + ' '
            + this.property.defaultCheckOutTime, 'error'));
          return;
        }
      }
    }

    if ((this.property.checkInOnline || this.property.lightOnLinePreCheck) && !this.property.neverAskTheGuestEmail) {
      if (this.onlineCheckIn.personalEmail == null || this.onlineCheckIn.personalEmail.length <= 0) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-personal-email'), 'error'));
        validation = false;
      } else if (this.onlineCheckIn.firstName == null || this.onlineCheckIn.firstName.length <= 0) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-firstname'), 'error'));
        validation = false;
      } else if (this.onlineCheckIn.lastName == null || this.onlineCheckIn.lastName.length <= 0) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-lastname'), 'error'));
        validation = false;
      } else if (this.onlineCheckIn.phoneNumber == null || this.onlineCheckIn.phoneNumber.length <= 0) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-phone'), 'error'));
        validation = false;
      }
    }


    if (this.property.checkInOnline && this.onlineCheckIn.checkStatus !== 'SKIPPED') {
      if (!this.onlineCheckIn.contractDocumentUrl && this.property.checkinOnlineWithContract && this.onlineCheckIn.checkStatus !== 'VALIDATED' && this.onlineCheckIn.checkStatus !== 'SKIPPED') {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.generate-contract'), 'error'));
        validation = false;
      } else if (!this.property.checkinOnlineWithNoIdentityProof
          && (this.onlineCheckIn.identityProofUrls == null || this.onlineCheckIn.identityProofUrls.length <= 0)) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-ids'), 'error'));
        validation = false;
      } else if (this.onlineCheckIn.guestCountry == null || this.onlineCheckIn.guestCountry.length <= 0) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-country'), 'error'));
        validation = false;
      } else if (this.onlineCheckIn.guestBirthDate == null || this.onlineCheckIn.guestBirthDate.length <= 0) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-birth'), 'error'));
        validation = false;
      } else if ((this.onlineCheckIn.contractDocumentUrl == null || this.onlineCheckIn.contractDocumentUrl === '') && this.property.checkinOnlineWithContract) {
        this.generateContract();
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-contract'), 'error'));
        validation = false;
      } else if (this.onlineCheckIn.cgvDocumentUrl != null && this.property.checkinOnlineWithCgv) {
        if (!this.localCgvValidated) {
          this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-cgv'), 'error'));
          validation = false;
        }
      } else if (this.property.checkinOnlineWithContract && this.onlineCheckIn.contractDocumentUrlSigned == null && this.signaturePad.isEmpty()) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('g11-online-checkin.missing-signature'), 'error'));
        validation = false;
      }
    }
    return validation;
  }

  protected readonly GuestIdentityDetails = GuestIdentityDetails;
}
export enum StepName {
  STEP_INFORMATION = 'STEP_INFORMATION',
  STEP_IDENTIFICATION = 'STEP_IDENTIFICATION',
  STEP_CONTRACT = 'STEP_CONTRACT',
  STEP_RECAP = 'STEP_RECAP'

  //STEP_EDIT = 'STEP_EDIT',
  //STEP_NON_EDIT = 'STEP_NON_EDIT'

}
export class Country {
  id: string;
  name: string;
}
export class TypeOfStay {
  id: string;
  name: string;
}

